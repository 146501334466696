<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_applications') + ' - ' + $t('academic_unit_office')"
                    @filter-div-status="datatable.filterStatus = $event" :isColumns="true">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_applications') + ' - Admin'"
                    @filter-div-status="datatable.filterStatus = $event" :isColumns="true">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="true" @exportExcel="exportExcel(datatable.queryParams)">
                <b-row>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('period') + ' / ' + $t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id
                                "></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number
                                " />
                        </b-form-group>
                    </b-col>

                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.to_faculty
                                " />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.to_faculty
                                " v-model="datatable.queryParams.filter.to_program" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('result')">
                            <b-form-select v-model="datatable.queryParams.filter.result"
                                :options="resultOptions" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="double_major_statuses"
                                v-model="datatable.queryParams.filter.status" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="detailFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t("detail").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <detail-form v-model="formData" :type="formType" @getItem="getItem" v-if="formProcess == 'detail'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

// Pages
import DetailForm from "./DetailForm";

// Service
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";

// Other
import qs from "qs";

import { exportExcel } from "../function"

export default {
    name: "DoubleMajorApplicationsAdmin",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        SemestersSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,

        DetailForm,
    },
    metaInfo() {
        return {
            title: this.$t("double_major_applications") + " - " + this.$t('academic_unit_office'),
        };
    },
    data() {
        return {
            exportExcel,
            formId: -1,
            noteId: -1,
            formData: {},
            formType: "",
            explanation: "",
            rejectId: -1,
            note: {
                en: { status: true },
                tr: { status: false },
            },
            formProcess: null,
            updateOptions: [
                { text: this.$t('minor_status_obj.waiting'), value: 'waiting' },
                { text: this.$t('minor_status_obj.waiting_prep_school'), value: 'waiting_prep_school' },
                { text: this.$t('minor_status_obj.waiting_academic_unit_office'), value: 'waiting_academic_unit_office' }
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("detail"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.showDetail(row.id, row);
                                },
                            },

                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: false,
                        hidden: true,
                    },
                    {
                        label: this.$t("period") + " / " + this.$t("semester"),
                        field: "semester",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return (
                                row.academic_year +
                                "<br>" +
                                this.getSemester(value)
                            );
                        },
                    },
                    {
                        label: this.$t("student"),
                        field: "student_number",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return (
                                row.student_number +
                                "<br>" +
                                row.name +
                                " " +
                                row.surname +
                                "<br>" +
                                row[this.getLocaleField("faculty_name")] +
                                "<br>" +
                                row[this.getLocaleField("program_name")]
                            );
                        },
                    },
                    {
                        label: this.$t("class"),
                        field: "class",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t("registration_date"),
                        field: "registration_date",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t("prep_status"),
                        field: "prep_status",
                        formatFn: (value) => {
                            return this.getLocaleText(value, 'name')
                        },
                        sortable: false,
                        hidden: false,
                    },

                    {
                        label: this.$t("preferences").toUpper(),
                        field: "double_major_preferences",
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = "";
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">';
                                value.forEach((item) => {
                                    html +=
                                        "<li>" +
                                        item[this.getLocaleField("faculty_name")]  +
                                        "<br>" +
                                        item[this.getLocaleField("program_name")] +
                                        "</li>";
                                });
                                html += "</ol>";
                            }

                            return html;
                        },
                    },
                    {
                        label: this.$t("result").toUpper(),
                        field: "result",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (!row.result) return '-'
                            let variant = ['placed'].includes(row.result) ? 'badge-success'
                                : row.result == 'not_placed' ? 'badge-danger' : 'badge-warning'

                            return (
                                `<span class="badge ${variant}">${this.$t(`application_result.${row.result}`)}</span>`);
                        },
                    },
                    {
                        label: this.$t("status").toUpper(),
                        field: "status",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            let variant = "badge-light";
                            if (value.includes("waiting"))
                                variant = "badge-warning";
                            else if (value == "declined")
                                variant = "badge-danger";
                            else if (value == "student_affairs_declined")
                                variant = "badge-danger";
                            else if (value == "approved")
                                variant = "badge-success";

                            const locale = this.$i18n.locale.split("-")[0];
                            const localizedStatusText = row[`status_text_${locale}`] || row.status_text || value;

                            return (
                                '<span class="badge ' +
                                variant +
                                '">' +
                                localizedStatusText +
                                "</span>"
                            );
                        },
                    },
                    {
                        label: this.$t("explanation").toUpper(),
                        field: "explanation",
                        hidden: false,
                        formatFn: (value) => {
                            return value || "-"
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            resultOptions: [
                { text: this.$t('application_result.placed'), value: 'placed' },
                { text: this.$t('application_result.not_placed'), value: 'not_placed' },
                { text: this.$t('application_result.registered'), value: 'registered ' }
            ],
        };
    },
    methods: {
        getItem(id) {
            DoubleMajorApplicationService.doubleMajorShowGetId(id)
                .then((response) => {
                    this.formData = response.data.data;
                    this.getRows()
                })
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async updateStatus() {
            const valid = await this.$refs['updateFormValid'].validate()
            if (!valid) {
                return
            }

            if (this.update.status !== 'declined') {
                delete this.update.explanation
            }

            const response = await DoubleMajorApplicationService.academicUnitOfficeApprove(this.update)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            if (response.data.success) {
                this.$refs.updateStatusFormModal.$refs.commonModal.hide()

                this.update = {}
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            return DoubleMajorApplicationService.academicUnitOfficeIndex(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        delete(id) {
            this.rejectId = id;
            this.explanation = "";
            this.$refs.declineFormModal.$refs.commonModal.show();
        },
        formClear() {
            this.formId = null;
            this.formData = {};
            this.formProcess = null;
        },
        showDetail(id, row) {
            this.formId = id;
            this.formData = row;
            this.formProcess = "detail";
            this.formType = "show";
            this.$refs.detailFormModal.$refs.commonModal.show();
        },
        showDetailSuccess() {
            this.$refs.detailFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },
    },
};
</script>
